import React, { useRef } from 'react';

import Container from '../components/Container';
import ThemeLink from '../components/ThemeLink';
import Layout from '../components/Layout/Layout';

import * as styles from './about.module.css';
import { Spacer } from '../uiBook';
const AboutPage = (props) => {
  let historyRef = useRef();
  let valuesRef = useRef();
  let sustainabilityRef = useRef();
  let kingdomRef = useRef();
  let importanceRef = useRef();

  const handleScroll = (elementReference) => {
    if (elementReference) {
      window.scrollTo({
        behavior: 'smooth',
        top: elementReference.current.offsetTop - 280,
      });
    }
  };

  return (
    <Layout disablePaddingBottom>
      <div className={styles.root}>
        {/* Hero Container */}
        {/* <Hero
          maxWidth={'900px'}
          image={'/aboutUs.webp'}
          title={`Preserving the past, enriching the present.`}
        /> */}

        <div className={styles.navContainer}>
          <ThemeLink onClick={() => handleScroll(historyRef)} to={'#history'}>
            About Us
          </ThemeLink>
          <ThemeLink onClick={() => handleScroll(valuesRef)} to={'#values'}>
            Values
          </ThemeLink>
          <ThemeLink
            onClick={() => handleScroll(sustainabilityRef)}
            to={'#sustainability'}
          >
            Our Founder
          </ThemeLink>
          <ThemeLink onClick={() => handleScroll(kingdomRef)} to={'#kingdom'}>
            Kingdom of Marion
          </ThemeLink>
          <ThemeLink
            onClick={() => handleScroll(importanceRef)}
            to={'#importance'}
          >
            Importance of Antiquities Trade
          </ThemeLink>
        </div>

        <Container size={'large'} spacing={'min'}>
          <Spacer height={48} />
          <div className={styles.content} ref={historyRef}>
            <h3>Preserving the past, enriching the present.</h3>
            <p>
              Welcome to Marion Ancient Art, where we bring the wonders of the
              past to the present. With a passion for antiquities, we curate and
              offer a diverse collection of ancient artifacts, from striking
              sculptures to intricately crafted pottery and exquisite jewellery.
              Our expert team meticulously selects each piece from trusted
              sources, ensuring its authenticity and historical significance,
              allowing you to own a tangible piece of history. Whether you are a
              seasoned collector or an avid lover of ancient art, Marion Ancient
              Art is your gateway to the timeless beauty and cultural heritage
              that antiquities embody. Explore our collection and embark on a
              captivating journey through the ages.
            </p>
            <br />
            <br />
          </div>
        </Container>

        <Container size={'large'} spacing={'min'}>
          <div className={styles.content}>
            <h3>Our Values</h3>
            <div ref={valuesRef}>
              <p>
                <span
                  style={{
                    fontStyle: 'italic',
                  }}
                >
                  Unearth the extraordinary at Marion Ancient Art – your premier
                  destination for authentic antiquities and unrivalled
                  expertise.
                </span>
                <Spacer height={12} />
                <span className="ol-para">
                  At Marion Ancient Art, we strive to provide a distinct and
                  unparalleled experience for our customers, setting us apart
                  from our competitors. Here&#39;s why:
                </span>
              </p>
              <ol>
                <li>
                  Trusted Expertise: With a team of knowledgeable specialists
                  with extensive experience and education in the field of
                  antiquities, we guarantee the authenticity and quality of
                  every artefact we offer.
                </li>
                <li>
                  Carefully Curated Collection: We take pride in curating a
                  diverse and captivating collection of ancient art, ensuring
                  that each piece holds historical significance and aesthetic
                  appeal.
                </li>
                <li>
                  Personalized Service: We understand that every client is
                  unique, and we take the time to understand your specific
                  preferences and interests. Our team is dedicated to providing
                  personalized guidance and recommendations to help you find the
                  perfect piece for your collection or as a thoughtful gift.
                </li>
                <li>
                  Commitment to Ethical Sourcing: We uphold the highest ethical
                  standards when acquiring ancient artifacts. As well as being
                  registered members of the Art Loss Register, we only buy from
                  trusted sources who provide authentic provenance for each
                  artefact. Our commitment to responsible sourcing ensures that
                  every piece has been legally and ethically obtained, allowing
                  you to purchase with confidence.
                </li>
                <li>
                  Seamless Buying Experience: From an easy-to-navigate website
                  to prompt and secure shipping, we prioritize delivering a
                  seamless and hassle-free buying experience, so you can focus
                  on the joy of owning a piece of ancient history. Through us,
                  you will embark on a captivating journey that seamlessly
                  blends ancient wonder with modern appreciation.
                </li>
              </ol>
            </div>
            <h3>Our Founder</h3>
            <div id={'#sustainability'} ref={sustainabilityRef}>
              <p>
                Alexis Kyriacou is the visionary founder behind Marion Ancient
                Art, bringing a wealth of expertise and passion for ancient art
                to the forefront. With a bachelor&#39;s degree in classics and a
                master&#39;s degree in ancient history, Alexis honed his
                knowledge and understanding of antiquities throughout his
                academic journey as a classical art specialist. Prior to
                establishing his own company, Alexis garnered invaluable
                experience working for renowned antiquity dealerships and
                auction houses. Motivated by a sense of responsibility to his
                roots, Alexis&#39; Greek heritage has not only shaped his
                academic &amp; career paths, but also emphasized the importance
                of creating an ethical dealership dedicated to the preservation
                of antiquities. Alexis is committed to operating with unwavering
                ethics in his quest to create a sustainable future for
                antiquities. His dedication to authenticity and commitment to
                providing unmatched guidance solidify Alexis as a trusted
                authority in the world of ancient art.
              </p>
              <Spacer height={32} />
              <img alt={'founder'} src={'/about2.jpeg'}></img>
            </div>
            <h3>Kingdom Of Marion</h3>
            <div id={'#kingdom'} ref={kingdomRef}>
              <p>
                <span
                  style={{
                    fontStyle: 'italic',
                  }}
                >
                  An ancient legacy…
                </span>
              </p>
              <p>
                The decision to name the company &quot;Marion Ancient Art&quot;
                was inspired by Alexis’ strong familial ties to Cyprus. The
                ancient Cypriot Kingdom of Marion, located in the northwest of
                Cyprus is renowned for its extensive finds of ancient art,
                including both locally crafted pieces and imports from
                neighbouring civilizations such as Greece, Egypt and Rome.
                Marion represents a powerful symbol of Cyprus&#39; rich cultural
                heritage. By naming the company after Marion, Alexis seeks to
                pay homage to his ancestry and to share the captivating stories
                and timeless beauty of ancient art with the world.
              </p>
            </div>
            <h3>Why is the Antiquities Trade Important?</h3>
            <div id={'#importance'} ref={importanceRef}>
              <p>
                <span
                  style={{
                    fontStyle: 'italic',
                  }}
                >
                  Bringing history to your doorstep…
                </span>
              </p>
              <p>
                The antiquities trade plays a crucial role in the preservation
                and appreciation of our cultural heritage. By engaging in this
                trade, we contribute to the study and understanding of our past,
                allowing us to connect with our roots and gain insights into
                ancient civilizations. Additionally, a well-regulated
                antiquities trade promotes ethical practices, ensuring that
                items are acquired legally and responsibly. The antiquities
                trade enables individuals to own valuable artifacts and
                treasures from the past, fostering a personal connection to
                history and providing tangible pieces of cultural heritage. By
                owning these treasures, people can immerse themselves in the
                beauty and significance of ancient civilizations, contributing
                to a deeper appreciation and understanding of our shared human
                story.
              </p>
            </div>
          </div>
        </Container>
        <Spacer height={100} />
      </div>
    </Layout>
  );
};

export default AboutPage;
